import Layout from '../../components/Layout';
import Helmet from 'react-helmet';
import React, { useState } from "react";
import { navigate } from 'gatsby';
import * as Liff from '../../apis/liff';
import { makeStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ErrorIcon from '../../assets/images/icons/error_cross_red.svg';
import {
  getUrlParams
} from '../../helpers/location';

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: "30px",
    paddingBottom: "40px",
    textAlign: "center",
    height: "100vh",
  },
  topText: {
    color: "#212121",
    padding: "32px",
  },
  nextButton: {
    fontSize: "1rem",
    borderRadius: "20px",
    backgroundColor: "#5B83C1",
    minWidth: "150px",
  },
  closeButton: {
    fontSize: "1rem",
    borderRadius: "20px",
    color: "#5B83C1",
    border: "1px solid #5B83C1",
    backgroundColor: "fff",
    minWidth: "150px",
  }
}));

export default ({ location }) => {
  const classes = useStyles();
  const { flow, nextPage, ...excludeNextPageParams } = getUrlParams(location.search);

  const gotoNextPage = () => {
    const additionalParams = { "passVerify": true };
    Object.assign(excludeNextPageParams, additionalParams);
    const queryString = new URLSearchParams(excludeNextPageParams).toString();
    const urlWithParams = `${nextPage}?${queryString}`
    return navigate(urlWithParams);
  }

  const closeWindow = () => {
    if (Liff.isInClient()) {
      Liff.closeWindow();
    } else {
      window.location.href = 'me.moronline://exit';
    }
  }

  const sorryText = (flow && flow == "consult")
    ? "ขอออภัยค่ะ คุณไม่มีสิทธิในการเบิกจ่ายสปสช. หากคุณต้องการปรึกษาแพทย์ต่อจะมีการคิดค่าใช้จ่ายตามจริง"
    : "ขอออภัยค่ะ คุณไม่มีสิทธิในการเบิกจ่ายสปสช. หากคุณต้องการซื้อยาต่อจะมีการคิดค่าใช้จ่ายตามจริง";
  
  const continueButtonText = (flow && flow == "consult")
    ? "ปรึกษาแพทย์"
    : "ซื้อยา";

  return (
    <Layout hideAppBar>
      <Helmet>
        <title>ขอออภัย</title>
      </Helmet>
      
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={classes.container}
        spacing={1}
      >
        <Grid item xs={12}>
        <img src={ErrorIcon} />
          <Typography
            variant="h6"
            gutterBottom
            className={classes.topText}
          >
            {sorryText}
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            color="primary"
            className={classes.nextButton}
            style={{ marginRight: "16px" }}
            onClick={gotoNextPage}
          >
            {continueButtonText}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            className={classes.closeButton}
            onClick={closeWindow}
          >
            ปิดหน้านี้
          </Button>
        </Grid>
      </Grid>
    </Layout>
  )
}